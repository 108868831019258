import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import {Link} from "react-scroll";
import "./Header.css";
import OutsideClickHandler from 'react-outside-click-handler';

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const mobile = window.innerWidth <= 768 ? true : false;

  return (
    <div className="header">
      <img src={Logo} className="logo" alt="Logo here" />
        
      <OutsideClickHandler
        onOutsideClick={()=> setMenuOpened(false)}
        >

      {!menuOpened && mobile ? (
        <div
          style={{
            backgroundColor: "var(--appColor)",
            borderRadius: "5px",
            padding: "0.5rem",
            cursor: "pointer"
          }}
          onClick={()=>{ setMenuOpened(true)}}
        >
          <img src={Bars} style={{ width: "1.5rem", height: "1.5rem" }} />
        </div>
      ) : (
       
        <ul className="header-menu">
          <li><Link
          onClick={()=> setMenuOpened(false)}
          to="home"
          smooth = {true}
          spy = {true}
          >Home</Link></li>

          <li><Link 
          onClick={()=> setMenuOpened(false)}
          to="programs"
          smooth = {true}
          spy = {true}
          >Programs</Link></li>

          <li><Link 
          onClick={()=> setMenuOpened(false)}
          to="reasons"
          smooth = {true}
          spy = {true}
          >Why us</Link></li>

          <li><Link 
          onClick={()=> setMenuOpened(false)}
          to="plans"
          smooth = {true}
          spy = {true}
          >Plans</Link></li>

          <li><Link 
          onClick={()=> setMenuOpened(false)}
          to="testimonials"
          smooth = {true}
          spy = {true}
          >Testimonials</Link></li>
        </ul>
      )}
      </OutsideClickHandler>
    </div>
  );
};

export default Header;
