import React from "react";
import "./Programs.css";
import { programsData } from "../../data/programsData";
import RightArrow from "../../assets/rightArrow.png";

const Programs = () => {
  return (
    <div className="programs" id="programs">
      {/* Header */}
      <div className="programs-header">
        <span className="stroke-text">explore our</span>
        <span>programs</span>
        <span className="stroke-text">to shape you</span>
      </div>

      {/* Programs Cards */}
      <div className="programs-cards">
        {programsData.map((cardData, i) => {
          return (
            <div key={i} className="card">
              {cardData.image}
              <span>{cardData.heading}</span>
              <span>{cardData.details}</span>
              <div className="join-now">
                <span>Join Now</span>
                <img src={RightArrow} alt="" />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default Programs;
