import React from "react";
import "./Hero.css";
import Header from "../Header/Header";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import {motion}  from "framer-motion";
import CountUp from 'react-countup';

const Hero = () => {
  const mobile = window.innerWidth<=410 ? true: false;
  return (
    <div className="hero" id="home">
        <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />

        {/* The Best ad */}
        <div className="best-ad">
          <motion.div
          initial={{left: mobile? "188px": "238px"}}
          whileInView={{ left: "8px"}}
          transition={{duration: 3, type: "tween"}}
          ></motion.div>
          <span>The best fitness club in the town</span>
        </div>
        {/* Hero HEading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>IDEAL BODY</span>
          </div>
          <p>
            In here we will help you to shape and build your ideal body and live
            up your life to fullest
          </p>
        </div>

        {/* Figures */}
        <div className="figures">
          <div>
            <span><CountUp start={0} end={140} duration={2} prefix="+"/></span>
            <span>expert coaches</span>
          </div>

          <div>
            <span><CountUp start={0} end={978} duration={2} prefix="+"/></span>
            <span>members joined</span>
          </div>

          <div>
            <span><CountUp start={0} end={50} duration={2} prefix="+"/></span>
            <span>fitness programs</span>
          </div>
        </div>

        {/* Hero Button */}
        <div className="hero-btn">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>

      {/* Right Side */}
      <div className="right-h">

        <button className="btn">Join Now</button>

        {/* Heart Rate Card */}  
        <motion.div
        initial={{right: "-1rem"}}
        whileInView={{right: "4rem"}}
        transition={{duration: 3, type: "spring"}}

        className="heart-rate">
          <img src={Heart} alt="Heart" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>

        {/* Hero Images */}
        <motion.img
        initial={{right: "1rem"}}
        whileInView={{right: "20rem"}}
        transition={{duration: 3, type: "spring"}}

         src={hero_image_back} className="hero-image-back" alt="" />
        <img src={hero_image} className="hero-img" alt="" />

        {/* Calories Card */}
        <motion.div 
         initial={{right: "37rem"}}
         whileInView={{right: "28rem"}}
         transition={{duration: 3, type: "spring"}}
        className="calories-card">
            <img src={Calories} alt="" />
            <div>
                <span>Calories Burned</span>
                <span>220 kcal</span>
            </div>
        </motion.div>

      </div>
    </div>
  )
};

export default Hero;
