import React from "react";
import "./Testimonials.css";
import { testimonialsData } from "../../data/testimonialsData";
import { useState } from "react";
import leftArrow from "../../assets/leftArrow.png"
import rightArrow from "../../assets/rightArrow.png"
import {motion} from "framer-motion"

const Testimonials = () => {
    const [selected, setSelected] = useState(0)
    let tLength = testimonialsData.length;
    // console.log(tLength)
    const next = ()=>{
        if(selected === tLength-1){
            setSelected(0)
        }else{
            setSelected(selected + 1)
        }
    }
    const previous = ()=>{
        if(selected===0){
            setSelected(tLength-1)
        }
        else{
            setSelected(selected-1)
        }
    }

  return (
    <div className="testimonials">

      <div className="left-t">

        <span>Testimonials</span>
        <span className="stroke-text">What they</span>
        <span>say about us</span>

        <motion.span
        key={selected} 
        initial={{opacity: 0, x: -100}}
        animate={{opacity: 1, x: 0}}
        exit={{opacity: 0, x: 100}}
        transition={{duration: 3, type: "spring"}}
        >{testimonialsData[selected].review}</motion.span>
        
        <div>
            <span>{testimonialsData[selected].status}</span>
            <span>- {testimonialsData[selected].name} </span>
        </div>

      </div>

      <div className="right-t">
        <motion.div
         initial={{opacity: 0, x: -100}}
         whileInView={{opacity: 1, x: 0}}
         transition={{duration: 2, type: "spring"}}
        ></motion.div>

        <motion.div
        initial={{opacity: 0, x: 100}}
        whileInView={{opacity: 1, x: 0}}
        transition={{duration: 2, type: "spring"}}
        ></motion.div>

        <motion.img
        key={selected}
        initial={{opacity: 0, x: 100}}
        animate={{opacity: 1, x: 0}}
        exit={{opacity: 0, x: -100}}
        transition={{duration: 3, type: "spring"}}

        src={testimonialsData[selected].image} alt="" />
        <div className="arrows">
            <img src={leftArrow} onClick={previous} alt="" />
            <img src={rightArrow} onClick={next}  alt="" />
        </div>
        
      </div>

    </div>
  );
};

export default Testimonials;
