import React from "react";
import "./Plans.css";
import { plansData } from "../../data/plansData";
import whiteTick from "../../assets/whiteTick.png";
import rightArroe from "../../assets/rightArrow.png";

const Plans = () => {
  return (
    <div className="plans-container" id="plans">
        <div className="blur plans-blur-1"></div>
        <div className="blur plans-blur-2"></div>

      <div className="plans-header">
        <span className="stroke-text">ready to start</span>
        <span>your journey</span>
        <span className="stroke-text">now withus</span>
      </div>

      <div className="plans">
        {
            plansData.map((data, i)=>{
                return(
                    <div key={i} className="plan">
                        {data.icon}
                        <span>{data.name}</span>
                        <span>$ {data.price}</span>

                        <div className="features">
                            {data.features.map((feature, index)=>
                                <div key={index}>
                                    <img src={whiteTick} alt="" />
                                    <span>{feature}</span>
                                </div>
                                )}
                        </div>

                        <div>
                            <span>See more benefits</span>
                            <img style={{width: "0.6rem"}} src={rightArroe} alt="" />
                        </div>
                        <button className="btn">Join now</button>
                    </div>
                )
            })
        }

      </div>
    </div>
  );
};

export default Plans;
